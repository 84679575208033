import queriesService from "@/services/queries";
import accountsService from "@/services/accounts";
import allConstantsMixin from "@/mixins/allConstants";

export default {
  mixins: [allConstantsMixin],
  computed: {
    currentQueryInCreation() {
      return this.$store.getters["currentQueryInCreation/currentQuery"];
    },
  },
  methods: {
    startCurrentQueryInCreation(isRegisteredUser, fromPublicSite) {
      let initialStep = this.allConstants.queries.creationSteps.previousStep;
      if (fromPublicSite) {
        initialStep = isRegisteredUser
          ? this.allConstants.queries.creationSteps.collectInstitutionData
          : this.allConstants.queries.creationSteps.collectBasicData;
      }

      return this.currentQueryInCreationCreateOrUpdate({
        registeredUser: isRegisteredUser,
        fromPublicSite: fromPublicSite,
        currentStep: initialStep,
      });
    },
    currentQueryInCreationCreateOrUpdate(query) {
      return this.$store.dispatch(
        "currentQueryInCreation/createOrUpdate",
        query
      );
    },
    currentQueryInCreationClear() {
      return this.$store.dispatch("currentQueryInCreation/clear");
    },
    currentQueryInCreationGoForeward() {
      const actualQueryInCreation = this.currentQueryInCreation;
      actualQueryInCreation.currentStep++;
      return this.currentQueryInCreationCreateOrUpdate(actualQueryInCreation);
    },
    currentQueryInCreationGoBackward() {
      const actualQueryInCreation = this.currentQueryInCreation;
      actualQueryInCreation.currentStep--;
      return this.currentQueryInCreationCreateOrUpdate(actualQueryInCreation);
    },
    createRealQuery() {
      let channel;

      let claimantIdentificationNumber;
      let claimantName;

      let claimCreatorId;
      let claimCreatorRolId;

      const currentUserAccount = this.$Authentication.getAccount();
      const currentUserIsAuthenticated = currentUserAccount ? true : false;

      if (!currentUserIsAuthenticated) {
        //Aquí se sabe que el reclamo esta siendo creado por un usuario anonimo auto-regitrado en el paso 2, desde el sitio publico

        channel = this.allConstants.queries.chanelCodes.claimantApp; //front
        claimantIdentificationNumber = this.currentQueryInCreation
          .collectedBasicData.identificationNumber;
        claimantName = `${this.currentQueryInCreation.collectedBasicData.name} ${this.currentQueryInCreation.collectedBasicData.surname}`;

        claimCreatorId = this.currentQueryInCreation.collectedBasicData
          .accountId;
        claimCreatorRolId = this.allConstants.security.userRolesCodes.claimant;
      } else {
        //Aquí se sabe que el reclamo esta siendo creado por un usuario identificado (no-animo)
        //Puede ser un cliente reclamente autenticado o un usuario del WF

        if (
          currentUserAccount.roles.filter(
            (role) => role == this.allConstants.security.userRolesCodes.claimant
          ).length > 0
        ) {
          channel = this.allConstants.queries.chanelCodes.claimantApp; //front
          claimantIdentificationNumber = currentUserAccount.userName;
          claimantName = currentUserAccount.visiblename;

          claimCreatorId = currentUserAccount.id;
          claimCreatorRolId = this.allConstants.security.userRolesCodes
            .claimant;
        }

        if (
          currentUserAccount.roles.filter(
            (role) =>
              role == this.allConstants.security.userRolesCodes.ddcExecutive ||
              role == this.allConstants.security.userRolesCodes.ddcDefender
          ).length > 0
        ) {
          channel = this.allConstants.queries.chanelCodes.ddcApp; //wf-ddc
          claimantIdentificationNumber = this.currentQueryInCreation
            .collectedBasicData.identificationNumber;
          claimantName = `${this.currentClaimInCreation.collectedBasicData.name} ${this.currentClaimInCreation.collectedBasicData.surname}`;

          claimCreatorId = currentUserAccount.id;
          claimCreatorRolId = currentUserAccount.roles[0];
        }
      }

      const queryData = {
        rut: claimantIdentificationNumber,
        name: claimantName,
        channel: channel,
        finnancialInstitute: this.currentQueryInCreation
          .collectedInstitutionData,
        comments: this.currentQueryInCreation.collectedRequestData,
        productType: this.currentQueryInCreation.collectedProductData[0],

        documents: this.currentQueryInCreation.collectedDocumentsData.map(
          (document) => {
            return {
              blobId: document.blobId,
              name: document.typeName,
              fileName: document.realName,
              contentType: document.blobContentType,
              contentLenght: document.blobSize.toString(),
            };
          }
        ),
      };

      return queriesService.create(
        queryData,
        claimCreatorId,
        claimCreatorRolId
      );
    },
    createAccount() {
      const accountData = {
        identificationNumber: this.currentQueryInCreation.collectedBasicData
          .identificationNumber,
        name: this.currentQueryInCreation.collectedBasicData.name,
        surname: this.currentQueryInCreation.collectedBasicData.surname,
        email: this.currentQueryInCreation.collectedBasicData.email,
        phone: this.currentQueryInCreation.collectedBasicData.phone.toString(),
        cellphone: this.currentQueryInCreation.collectedBasicData.cellphone.toString(),
        address: {
          region: this.currentQueryInCreation.collectedAddressData.region,
          city: this.currentQueryInCreation.collectedAddressData.province,
          commune: this.currentQueryInCreation.collectedAddressData.commune,
          streetAddress: this.currentQueryInCreation.collectedAddressData
            .street,
          apartment: this.currentQueryInCreation.collectedAddressData.apartment,
          building: this.currentQueryInCreation.collectedAddressData.building,
        },
        bank: {
          code: "0",
        },
        roleId: this.allConstants.security.userRolesCodes.claimant,
        includeCredentialCreation: true,
        passwordForCredential: this.currentQueryInCreation.collectedBasicData
          .secret,
        userType: this.currentQueryInCreation.userType,
      };

      return accountsService.create(accountData);
    },
    updateAccount() {
      const accountData = {
        name: this.currentQueryInCreation.collectedBasicData.name,
        surname: this.currentQueryInCreation.collectedBasicData.surname,
        email: this.currentQueryInCreation.collectedBasicData.email,
        phone: this.currentQueryInCreation.collectedBasicData.phone.toString(),
        cellphone: this.currentQueryInCreation.collectedBasicData.cellphone.toString(),
        address: {
          region: this.currentQueryInCreation.collectedAddressData.region,
          city: this.currentQueryInCreation.collectedAddressData.province,
          commune: this.currentQueryInCreation.collectedAddressData.commune,
          streetAddress: this.currentQueryInCreation.collectedAddressData
            .street,
          apartment: this.currentQueryInCreation.collectedAddressData.apartment,
          building: this.currentQueryInCreation.collectedAddressData.building,
        },
      };

      return accountsService.update(
        this.currentQueryInCreation.collectedBasicData.accountId,
        accountData
      );
    },
  },
};
