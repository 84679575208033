<template>
    <section class="container-fluid g-px-60--lg g-px-50--sm g-pt-20">
        <br/>
        <br/>
        <div class="media g-mb-30">
            <div class="media-body g-bg-secondary g-pa-10 g-rounded-5">
                <div class="row">
                    <div class="col-sm-4 col-lg-4">
                        <p class="h5 g-pt-10 font-text"><img src="assets/img/name.svg"> {{currentUserInfo.visiblename.toUpperCase()}}</p>
                    </div>
                    <div class="col-sm-4 col-lg-4">
                        <p class="h5 g-pt-10 font-text"><img src="assets/img/mail.svg"> {{currentUserInfo.email}}</p>
                    </div>
                    <div class="col-sm-4 col-lg-4">
                        <p class="h5 g-pt-10 font-text"><img src="assets/img/telephone.svg"> 
                            <span>+56 9 {{currentUserInfo.cellphone}}</span>
                            <span class="ml-2" v-if="currentUserInfo.userType == allConstants.security.userTypes.business">/ +56 {{currentUserInfo.phone}}</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import baseViewMixin from "@/mixins/baseView";
    import currentUserMixin from "@/mixins/currentUser"; 
    export default {
        mixins: [baseViewMixin, currentUserMixin],
    }
</script>

<style lang="scss" scoped>
.font-text {
    font-weight: 500;
    font-size: 1.25rem;
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    line-height: 1.6;
    color: #555;
}
</style>