<template>
  <div>
    <MyResume />
    <section class="container-fluid g-px-70--lg g-px-50--sm g-pt-10">
      <div class="row">
        <div class="col-sm-6 col-lg-6">
          <h5 class="d-flex align-items-center g-color-gray-dark-v4 mb-0">
            <p class="g-font-size-20--sm g-font-size-26--lg">
              <router-link :to="{ name: 'resume' }">
                <img src=" assets/img/arrow.svg" />
              </router-link>
              Consultas realizadas
            </p>
          </h5>
        </div>
        <div class="col-sm-6 col-lg-6 text-center ">
          <router-link
            to="#"
            v-on:click.native="beginCreateNewQuery"
            class="btn btn-md u-btn-outline-orange  g-mr-10 g-mb-15 g-color-orange"
          >
            Ingresar nueva consulta <img src="assets/img/new-query-y.svg" />
          </router-link>
        </div>
      </div>
      <hr class="g-my-10 " style="border: 1px solid; " />
    </section>

    <section
      class="container-fluid g-px-100 g-pt-90 g-pb-30"
      v-if="page.loading"
    >
      <v-progress-linear indeterminate color="orange"></v-progress-linear>
    </section>

    <section
      class="container-fluid g-px-80--lg g-px-50--sm g-pt-40 g-mb-100"
      v-else
    >
      <div class="row ">
        <div class="col-sm-12 col-lg-12 ">
          <div class="table-responsive ">
            <table class="table table-bordered ">
              <thead>
                <tr class="g-bg-cyan g-color-white ">
                  <th>Folio</th>
                  <th>Institución</th>
                  <th>Producto</th>
                  <th>Fecha ingreso</th>
                  <th>Estado</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="query in userQueries" :key="query.id">
                  <td class="g-color-blue ">
                    <v-progress-linear
                      buffer-value="0"
                      stream
                      color="cyan"
                      v-if="form.processing"
                    ></v-progress-linear>
                    <router-link
                      to="#"
                      v-on:click.native="gotoQuery(query.id, query.rut)"
                      v-else
                    >
                      {{ query.folio }}
                    </router-link>
                  </td>
                  <td>{{ getBankName(query.finnancialInstitute) }}</td>
                  <td>
                    {{ getProductName(query.productType) }}
                  </td>
                  <td>{{ query.creationDate | formatDateShort }}</td>
                  <td
                    class="orange--text"
                    v-if="
                      allConstants.dashboard.groups.claimant.queriesActive.statuses.includes(
                        query.state
                      )
                    "
                  >
                    <span
                      class="blue--text"
                      v-if="query.state == 'PENDIENTE'"
                      >{{ "Ingresada" }}</span
                    >
                    <span v-else>{{ "Respondida" }}</span>
                  </td>
                  <td
                    class="green--text"
                    v-if="
                      allConstants.dashboard.groups.claimant.queriesSolved.statuses.includes(
                        query.state
                      )
                    "
                  >
                    <span>{{ "Respondida" }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import baseViewMixin from "@/mixins/baseView";
import parametersMixin from "@/mixins/parameters";
import currentUserMixin from "@/mixins/currentUser";
import currentQueryInCreationMixin from "@/mixins/currentQueryInCreation";
import currentQuerySelectedMixin from "@/mixins/currentQuerySelected";

import MyResume from "@/components/PublicSite/User/MyResume";

import queriesService from "@/services/queries";

export default {
  mixins: [
    baseViewMixin,
    parametersMixin,
    currentUserMixin,
    currentQueryInCreationMixin,
    currentQuerySelectedMixin,
  ],
  components: { MyResume },
  props: {
    macroStatusesToInclude: {
      type: Array,
    },
  },
  data() {
    return {
      formFields: {},
      userQueries: [],
    };
  },
  methods: {
    async beginCreateNewQuery() {
      const isRegisteredUser = true;
      const fromPublicSite = true;

      await this.startCurrentQueryInCreation(isRegisteredUser, fromPublicSite);

      let currentQueryInCreation = this.currentQueryInCreation;
      currentQueryInCreation.userType = this.currentUserInfo.userType;

      await this.currentQueryInCreationCreateOrUpdate(currentQueryInCreation);

      this.replaceView("queryCreationFlow");
    },
    async gotoQuery(queryId, userIdentification) {
      this.initSignalProcessing();

      await this.loadQuery(queryId, userIdentification);

      this.gotoView("querydetail");
    },
    gotoMain() {
      this.gotoView("resume", { id: this.$uuid.v4() });
    },
  },
  async beforeMount() {
    this.initSignalLoading();

    let macroStatusesFilter = this.macroStatusesToInclude;
    if (!macroStatusesFilter) {
      if (
        this.currentPage.routerName == this.$route.name &&
        this.currentPage.macroStatusesToInclude
      ) {
        macroStatusesFilter = this.currentPage.macroStatusesToInclude;
      }
    }
    const getResult = await queriesService.GetQueriesByClient(
      this.currentUserInfo.userName
    );
    this.userQueries = getResult.data.filter((query) =>
      macroStatusesFilter.includes(query.state)
    );

    let currentPageData = this.currentPage;
    currentPageData.routerName = this.$route.name;
    currentPageData.macroStatusesToInclude = macroStatusesFilter;
    this.createOrUpdateCurrentPage(currentPageData);

    this.stopSignalLoading();
  },
};
</script>

<style lang="scss" scoped></style>
